"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SettingsNameToRequestType = exports.RequestSortOrders = exports.RequestTimeRanges = exports.RequestTypes = exports.RequestValueKeys = exports.Sources = exports.RequestStatus = exports.PhoneStatusToInquirySettingsName = exports.PhoneStatus = void 0;
var PhoneStatus;
(function (PhoneStatus) {
    PhoneStatus["COMPLETED"] = "COMPLETED";
    PhoneStatus["UNCOMPLETED"] = "UNCOMPLETED";
    PhoneStatus["CANCELED"] = "CANCELED";
})(PhoneStatus = exports.PhoneStatus || (exports.PhoneStatus = {}));
var PhoneStatusToInquirySettingsName;
(function (PhoneStatusToInquirySettingsName) {
    PhoneStatusToInquirySettingsName["UNCOMPLETED"] = "uncompletedCallsAt";
    PhoneStatusToInquirySettingsName["CANCELED"] = "canceledCallsAt";
})(PhoneStatusToInquirySettingsName = exports.PhoneStatusToInquirySettingsName || (exports.PhoneStatusToInquirySettingsName = {}));
var RequestStatus;
(function (RequestStatus) {
    RequestStatus["OPEN"] = "open";
    RequestStatus["PROGRESS"] = "progress";
    RequestStatus["CLOSED"] = "closed";
})(RequestStatus = exports.RequestStatus || (exports.RequestStatus = {}));
var Sources;
(function (Sources) {
    Sources["PHONE"] = "PHONE";
    Sources["CONTACT_PAGE"] = "CONTACT_PAGE";
    Sources["MESSENGER"] = "MESSENGER";
    Sources["QUESTIONNAIRE"] = "QUESTIONNAIRE";
})(Sources = exports.Sources || (exports.Sources = {}));
var RequestValueKeys;
(function (RequestValueKeys) {
    RequestValueKeys["PATIENT_INSURANCE_TYPE_OR_MEDFLEX"] = "patientInsuranceTypeOrMedflex";
    RequestValueKeys["APPOINTMENT_ACTION"] = "appointmentAction";
    RequestValueKeys["APPOINTMENT_REASON"] = "appointmentReason";
    RequestValueKeys["APPOINTMENT_TYPE"] = "appointmentType";
    RequestValueKeys["SYMPTOM_DESCRIPTION"] = "symptomsDescription";
    RequestValueKeys["SYMPTOM_DURATION"] = "symptomsDuration";
    RequestValueKeys["EXISTING_APPOINTMENT_DATE_TIME"] = "existingAppointmentDateTime";
    RequestValueKeys["APPOINTMENT_DATE_TYPE"] = "appointmentDateType";
    RequestValueKeys["APPOINTMENT_AVAILABILITY"] = "appointmentAvailability";
    RequestValueKeys["DIAGNOSIS_DETAILS"] = "diagnosisDetails";
    RequestValueKeys["DIAGNOSIS_REQUEST_TYPE"] = "diagnosisRequestType";
    RequestValueKeys["AFTER_CARE_DETAILS"] = "afterCareDetails";
    RequestValueKeys["FOLLOW_UP_PRESCRIPTION"] = "followUpPrescription";
    RequestValueKeys["GENERAL_INQUIRY_DETAILS"] = "generalInquiryDetails";
    RequestValueKeys["REFERRAL_REASON"] = "referralReason";
    RequestValueKeys["DOCUMENTS_DELIVERY"] = "documentsDelivery";
    RequestValueKeys["CHECK_UP_DETAILS"] = "checkUpDetails";
    RequestValueKeys["ADDITIONAL_COMMENT"] = "additionalComment";
    RequestValueKeys["FILES"] = "files";
    RequestValueKeys["PHONE_TRANSCRIPT"] = "phoneTranscript";
    RequestValueKeys["CALL"] = "call";
    RequestValueKeys["PHONE_AUDIO_RECORDING_URL"] = "phoneAudioRecordingUrl";
    RequestValueKeys["DOCUMENTS"] = "documents";
})(RequestValueKeys = exports.RequestValueKeys || (exports.RequestValueKeys = {}));
var RequestTypes;
(function (RequestTypes) {
    RequestTypes["APPOINTMENT"] = "appointment";
    RequestTypes["FINDINGS_INQUIRY"] = "findings_inquiry";
    RequestTypes["REFERRAL"] = "referral";
    RequestTypes["GENERAL_INQUIRY"] = "general_inquiry";
    RequestTypes["REQUEST_FOLLOW_UP_PRESCRIPTION"] = "request_follow_up_prescription";
    RequestTypes["CONNECT_TO_PHYSICIAN"] = "connect_to_physician";
    RequestTypes["PHONE"] = "phone";
    RequestTypes["DOCUMENT"] = "document";
})(RequestTypes = exports.RequestTypes || (exports.RequestTypes = {}));
var RequestTimeRanges;
(function (RequestTimeRanges) {
    RequestTimeRanges["TODAY"] = "today";
    RequestTimeRanges["YESTERDAY"] = "yesterday";
    RequestTimeRanges["LAST_7_DAYS"] = "last_7_days";
    RequestTimeRanges["LAST_30_DAYS"] = "last_30_days";
    RequestTimeRanges["OLDER_THAN_30_DAYS"] = "older_than_30_days";
})(RequestTimeRanges = exports.RequestTimeRanges || (exports.RequestTimeRanges = {}));
var RequestSortOrders;
(function (RequestSortOrders) {
    RequestSortOrders["NEWEST"] = "newest";
    RequestSortOrders["OLDEST"] = "oldest";
    RequestSortOrders["IMPORTANT"] = "important";
})(RequestSortOrders = exports.RequestSortOrders || (exports.RequestSortOrders = {}));
var SettingsNameToRequestType;
(function (SettingsNameToRequestType) {
    SettingsNameToRequestType["appointment"] = "appointment";
    SettingsNameToRequestType["findingsInquiry"] = "findings_inquiry";
    SettingsNameToRequestType["referral"] = "referral";
    SettingsNameToRequestType["generalInquiry"] = "general_inquiry";
    SettingsNameToRequestType["requestFollowUpPrescription"] = "request_follow_up_prescription";
    SettingsNameToRequestType["connectToPhysician"] = "connect_to_physician";
})(SettingsNameToRequestType = exports.SettingsNameToRequestType || (exports.SettingsNameToRequestType = {}));
